import React from "react";
import { motion } from "framer-motion";
import QuoteBox from "../components/QuoteBox";
import { testimonialList } from "../assets/Data";

import "../styles/Testimonial.css";

const Testimonial = React.forwardRef((props, ref) => {
  return (
    <section className="testimonial" ref={ref}>
      <motion.div
        transition={{
          ease: "easeInOut",
          delay: 0.1,
          duration: 0.3,
        }}
        initial={{ opacity: 0, transform: "translateX(-30px)" }}
        whileInView={{ opacity: 1, transform: "translateX(0px)" }}
        viewport={{ once: true }}
      >
        <h1>Testimonial</h1>
      </motion.div>
      <div className="testimonialDescription">
        {testimonialList.slice(0, 3).map((item, index) => {
          return (
            <motion.div
              className="quoteBox"
              transition={{
                delay: 0.2 + index * 0.2,
                duration: 0.3,
                times: [0, 1],
                type: "linear",
              }}
              whileInView={{
                opacity: [0, 1],
                transform: ["translateY(100px)", "translateY(0px)"],
              }}
              viewport={{ once: true }}
              key={index}
            >
              <QuoteBox testimonial={{ ...item, index: index }} />
            </motion.div>
          );
        })}
      </div>
    </section>
  );
});

export default Testimonial;
