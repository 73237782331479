import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { Link } from "react-scroll";
import { BsArrow90DegRight } from "react-icons/bs";

import "../styles/Home.css";
import RandomImage from "../components/RandomImage";

const Home = React.forwardRef((props, ref) => {
  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();
  const controls4 = useAnimation();
  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      controls1.start({
        y: 0,
        opacity: 1,
        transition: { duration: 0.4, delay: 1.5, ease: "easeInOut" },
      });
      controls2.start({
        y: 0,
        opacity: 1,
        transition: { duration: 0.4, delay: 1.65, ease: "easeInOut" },
      });
      controls3.start({
        y: 0,
        opacity: 1,
        transition: { duration: 0.4, delay: 1.8, ease: "easeInOut" },
      });
      controls4.start({
        y: 0,
        opacity: 1,
        transition: { duration: 0.4, delay: 2, ease: "easeInOut" },
      });
    }
  }, [controls1, controls2, controls3, controls4, isInitialRender]);

  return (
    <>
      <div ref={ref}>
        <RandomImage index={0} />
        <RandomImage index={1} />
        <RandomImage index={2} />
        <RandomImage index={3} />
        <div className="home">
          <motion.div
            initial={{ y: -10, opacity: 0 }}
            animate={controls1}
            className="picMain"
          >
            <div className="picCont" />
            <div className="hiddenText">
              {"That's me :)"}
              <BsArrow90DegRight className="arrowRight" />
            </div>
          </motion.div>
          <motion.div initial={{ y: -10, opacity: 0 }} animate={controls2}>
            <h1>Dr. Nathalie Al Makdessi</h1>
          </motion.div>
          <motion.div initial={{ y: -10, opacity: 0 }} animate={controls3}>
            <p>Data Scientist</p>
          </motion.div>
          <motion.div initial={{ y: -10, opacity: 0 }} animate={controls4}>
            <Link
              href="#"
              to={"about"}
              className="ctaHome"
              spyThrottle={2500}
              spy={true}
              offset={-60}
            >
              Discover me
            </Link>
          </motion.div>
        </div>
      </div>
    </>
  );
});

export default Home;
