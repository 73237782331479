/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";

import image1 from "../assets/images/func.svg";
import image2 from "../assets/images/pi.svg";
import image3 from "../assets/images/sum.svg";
import image4 from "../assets/images/infinity.svg";

import "../styles/Home.css";

const RandomImage = (props) => {
  const randomPosition = () => {
    const x = 15 + Math.floor(Math.random() * 70);
    const y = 15 + Math.floor(Math.random() * 70);
    return { top: `${y}vh`, left: `${x}%` };
  };

  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState(randomPosition());
  const imageRef = useRef(null);

  let images = [image1, image2, image3, image4];

  let index = props.index || 0;

  let image = images[index % images.length];

  const showImage = () => {
    setIsVisible(true);
    setPosition(randomPosition());
    setTimeout(() => {
      setIsVisible(false);
    }, 1800);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      showImage();
    }, 3000 + Math.random() * 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <img
      ref={imageRef}
      src={image}
      alt="Random"
      className={`imageHome ${isVisible ? "fade-in" : "fade-out"}`}
      style={position}
    />
  );
};

export default RandomImage;
