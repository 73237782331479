import React from "react";
import { FaPlane, FaMusic, FaHandHoldingHeart } from "react-icons/fa";
import { CgGym } from "react-icons/cg";
import { motion } from "framer-motion";
import SvgAtom from "../components/SvgAtom";
import CircleIcon from "../components/CircleIcon";

import "../styles/About.css";

const About = React.forwardRef((props, ref) => {
  let icons = [
    <FaPlane className="circleIcon" />,
    <FaMusic className="circleIcon" />,
    <FaHandHoldingHeart className="circleIcon" />,
    <CgGym className="circleIcon" />,
  ];
  return (
    <div className="about" ref={ref}>
      <div>
        <motion.div
          transition={{
            ease: "easeInOut",
            delay: 0.2,
            duration: 0.3,
          }}
          initial={{ opacity: 0, transform: "translateX(-30px)" }}
          whileInView={{ opacity: 1, transform: "translateX(0px)" }}
          viewport={{ once: true }}
        >
          <h1>About me</h1>
        </motion.div>
        <div className="aboutSection">
          <motion.div
            transition={{
              ease: "easeInOut",
              delay: 0.1,
              duration: 0.3,
            }}
            initial={{ opacity: 0, transform: "translateX(-30px)" }}
            whileInView={{ opacity: 1, transform: "translateX(0px)" }}
            viewport={{ once: true }}
            className="leftAbout"
          >
            <div className="aboutText">
              <p>
                Hello !
                <br />
                <br /> My name is Nathalie Al Makdessi, and I'm a Data Scientist
                with <span className="bold">6+ years</span> of broad-based
                experience building data-intensive applications.
                <br />I am mainly proficient in{" "}
                <span className="bold">data mining</span>,{" "}
                <span className="bold">data processing</span>, and{" "}
                <span className="bold">machine learning</span>, as well as{" "}
                <span className="bold">scripting languages</span>.
              </p>
            </div>
            <div className="iconsAbout">
              {icons.map((icon, index) => {
                return (
                  <motion.div
                    transition={{
                      ease: "easeInOut",
                      delay: index * 0.05,
                      duration: 0.3,
                    }}
                    initial={{ opacity: 0, transform: "translateY(30px)" }}
                    whileInView={{ opacity: 1, transform: "translateY(0px)" }}
                    viewport={{ once: true }}
                    key={index}
                  >
                    <CircleIcon key={index} icon={icon} />
                  </motion.div>
                );
              })}
            </div>
          </motion.div>
          <motion.div
            transition={{
              ease: "easeInOut",
              delay: 0.3,
              duration: 0.3,
            }}
            initial={{ opacity: 0, transform: "translateX(30px)" }}
            whileInView={{ opacity: 1, transform: "translateX(0px)" }}
            viewport={{ once: true }}
            className="rightAbout"
          >
            <SvgAtom />
          </motion.div>
        </div>
      </div>
    </div>
  );
});

export default About;
