import React from "react";
import { motion } from "framer-motion";
import SkillBox from "../components/SkillBox";
import { skillList } from "../assets/Data";

import "../styles/Skills.css";

const Skills = React.forwardRef((props, ref) => {
  return (
    <section className="skills" ref={ref}>
      <motion.div
        transition={{
          ease: "easeInOut",
          delay: 0.1,
          duration: 0.3,
          type: "linear",
        }}
        initial={{ opacity: 0, transform: "translateX(-30px)" }}
        whileInView={{ opacity: 1, transform: "translateX(0px)" }}
        viewport={{ once: true }}
      >
        <h1>Skills</h1>
      </motion.div>
      <div className="skillsDescription">
        {skillList.map((item, index) => {
          return (
            <motion.div
              transition={{
                delay: 0.2,
                duration: 0.3,
                times: [0, 1],
                type: "linear",
              }}
              whileInView={{
                opacity: [0, 1],
                transform: [
                  "translateX(" + (index % 2 === 0 ? "-" : "") + "100px)",
                  "translateX(0px)",
                ],
              }}
              viewport={{ once: true }}
              key={index}
            >
              <SkillBox title={item.title} skills={item.skills} />
            </motion.div>
          );
        })}
      </div>
    </section>
  );
});

export default Skills;
