import React from "react";
import { motion } from "framer-motion";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { FaSchool } from "react-icons/fa";
import { MdWorkOutline } from "react-icons/md";
import { MdChildFriendly } from "react-icons/md";
import "react-vertical-timeline-component/style.min.css";
import "../styles/Career.css";

import { career } from "../assets/Data";

const Career = React.forwardRef((props, ref) => {
  return (
    <section className="career" ref={ref}>
      <motion.div
        transition={{
          ease: "easeInOut",
          delay: 0.1,
          duration: 0.3,
        }}
        initial={{ opacity: 0, transform: "translateX(-30px)" }}
        whileInView={{ opacity: 1, transform: "translateX(0px)" }}
        viewport={{ once: true }}
      >
        <h1>Career</h1>
      </motion.div>
      <VerticalTimeline animate={false} lineColor="#fff">
        {career.map((item, index) => {
          return (
            <VerticalTimelineElement
              key={index}
              className="vertical-timeline-element--work careerBox"
              contentStyle={{}}
              contentArrowStyle={{
                borderRight: "7px solid  var(--primary)",
              }}
              date={item.date}
              dateClassName="dateTime"
              iconStyle={{
                background:
                  item.type === "Work" ? "var(--blue1)" : "var(--pink1)",
                color: "#fff",
              }}
              icon={item.type === "Work" ? <MdWorkOutline /> : <FaSchool />}
            >
              <h3 className="vertical-timeline-element-title">{item.title}</h3>
              <h4 className="vertical-timeline-element-subtitle">
                {item.location}
              </h4>
              <p>{item.company}</p>
            </VerticalTimelineElement>
          );
        })}
        <VerticalTimelineElement
          iconStyle={{ background: "var(--green1)", color: "#fff" }}
          icon={<MdChildFriendly />}
        />
      </VerticalTimeline>
    </section>
  );
});

export default Career;
