import "../styles/Publications.css";

const getColor = (type) => {
  switch (type) {
    case "Thesis":
      return "var(--green1)";
    case "Article":
      return "var(--blue1)";
    case "Poster":
      return "var(--pink1)";
    default:
      return "var(--blue1)";
  }
};

const PublishBox = (props) => {
  let publication = props.publication;
  return (
    <a href={publication.link} target="_blank" rel="noreferrer">
      <div className="publishTop">
        <h4>{publication.title}</h4>
        <div className="publishDate">{publication.date}</div>
      </div>
      <div className="publishAuthors">{publication.authors.join(", ")}</div>
      <div>
        <span
          className="publishType"
          style={{
            background: getColor(publication.type),
          }}
        >
          {publication.type}
        </span>
      </div>
    </a>
  );
};

export default PublishBox;
