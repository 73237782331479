import React from "react";
import { BsQuote } from "react-icons/bs";

import { colors } from "../assets/Data";
import "../styles/QuoteBox.css";

const QuoteBox = (props) => {
  let testimonial = props.testimonial;
  let color = colors[testimonial.index % colors.length];
  return (
    <div className="quoteB">
      <div className="quoteIconCont">
        <div className="quoteIcon" style={{ color: color }}>
          <BsQuote />
        </div>
      </div>
      <div className="quoteText">
        <p>{testimonial.quote}</p>
      </div>
      <div className="greenRectangle" style={{ backgroundColor: color }}>
        <div className="quoteMiddle">
          <svg
            shapeRendering="geometricPrecision"
            xmlns="http://www.w3.org/1999/xhtml"
            viewBox="0 0 500 50"
            x="0"
            y="0"
          >
            <path
              d="M0,25 C250,50 250,0 500,20 L500,00 L0,0 Z"
              fill="var(--gray1)"
            ></path>
          </svg>
          <img
            src={testimonial.icon}
            alt="testimonial"
            className="quoteImage"
          />
        </div>
        <div className="quoteBottom">
          <div className="quoteAuthor">
            <p>{testimonial.author}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteBox;
