import React from "react";
import { motion } from "framer-motion";

import { publications } from "../assets/Data";
import PublishBox from "../components/PublishBox";

import "../styles/Publications.css";

const Publications = React.forwardRef((props, ref) => {
  return (
    <section className="publications" ref={ref}>
      <motion.div
        transition={{
          ease: "easeInOut",
          delay: 0.1,
          duration: 0.3,
        }}
        initial={{ opacity: 0, transform: "translateX(-30px)" }}
        whileInView={{ opacity: 1, transform: "translateX(0px)" }}
        viewport={{ once: true }}
      >
        <h1>Publications</h1>
      </motion.div>
      <div className="publishDescription">
        {publications.map((item, index) => {
          return (
            <motion.div
              className="publishBox"
              transition={{
                delay: 0.2,
                duration: 0.3,
                times: [0, 1],
                type: "linear",
              }}
              whileInView={{
                opacity: [0, 1],
                transform: [
                  "translateX(" + (index % 2 === 0 ? "-" : "") + "100px)",
                  "translateX(0px)",
                ],
              }}
              viewport={{ once: true }}
              key={index}
            >
              <PublishBox publication={item} key={index} />
            </motion.div>
          );
        })}
      </div>
    </section>
  );
});

export default Publications;
