import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";

import "../styles/Contact.css";
import { useRef } from "react";

const ContactForm = () => {
  const { register, handleSubmit, reset } = useForm();
  const form = useRef(null);

  const onSubmit = (data) => {
    emailjs.sendForm(
      "service_shr45kf",
      "template_c634fhs",
      form.current,
      "txXHsZ8I-3t8ykKrj"
    );
    reset();
  };

  return (
    <div className="App">
      <form ref={form} onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label htmlFor="fullname">Name</label>
          <input required placeholder="John Doe" {...register("fullname")} />
        </div>

        <div>
          <label htmlFor="subject">Subject</label>
          <input
            required
            placeholder="Interview request"
            {...register("subject")}
          />
        </div>

        <div>
          <label htmlFor="email">Email</label>
          <input
            required
            placeholder="john.doe@mail.com"
            type="email"
            {...register("email")}
          />
        </div>
        <div>
          <label htmlFor="message">Message</label>
          <textarea
            required
            className="message"
            placeholder="Hi, I'm interested in your work."
            {...register("message")}
          />
        </div>
        <button type="submit">Send</button>
      </form>
    </div>
  );
};

export default ContactForm;
