import { BsGithub } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import { SiResearchgate } from "react-icons/si";

export const headerItems = [
  {
    name: "Home",
    path: "home",
  },
  {
    name: "Skills",
    path: "skills",
  },
  {
    name: "Publications",
    path: "publications",
  },
  {
    name: "Career",
    path: "career",
  },
  {
    name: "Contact",
    path: "contact",
  },
  {
    name: "Download CV",
    path: "resume",
  },
];

export const skillList = [
  {
    title: "Programming Languages",
    skills: [
      { name: "Python", icon: require("../assets/images/skills/Python.webp") },
      { name: "R", icon: require("../assets/images/skills/R.webp") },
      { name: "SQL", icon: require("../assets/images/skills/SQL.webp") },
      { name: "MATLAB", icon: require("../assets/images/skills/MATLAB.webp") },
    ],
  },
  {
    title: "Data Manipulation and Analysis",
    skills: [
      { name: "Pandas", icon: require("../assets/images/skills/Pandas.webp") },
      { name: "NumPy", icon: require("../assets/images/skills/NumPy.webp") },
      { name: "Dplyr", icon: require("../assets/images/skills/Dplyr.webp") },
      {
        name: "Tidyverse",
        icon: require("../assets/images/skills/Tidyverse.webp"),
      },
    ],
  },
  {
    title: "Data Visualization",
    skills: [
      {
        name: "Matplotlib",
        icon: require("../assets/images/skills/Matplotlib.webp"),
      },
      {
        name: "Seaborn",
        icon: require("../assets/images/skills/Seaborn.webp"),
      },
      {
        name: "Ggplot2",
        icon: require("../assets/images/skills/Ggplot2.webp"),
      },
      {
        name: "Plotly",
        icon: require("../assets/images/skills/Plotly.webp"),
      },
    ],
  },
  {
    title: "Machine Learning and AI Frameworks",
    skills: [
      {
        name: "Scikit-learn",
        icon: require("../assets/images/skills/Scikit-learn.webp"),
      },
      {
        name: "TensorFlow",
        icon: require("../assets/images/skills/TensorFlow.webp"),
      },
      { name: "Keras", icon: require("../assets/images/skills/Keras.webp") },
      {
        name: "PyTorch",
        icon: require("../assets/images/skills/PyTorch.webp"),
      },
    ],
  },
];

export const publications = [
  {
    title:
      "A spectral correction method for multi-scattering effects in close range hyperspectral imagery of vegetation scenes: application to nitrogen content assessment in wheat",
    type: "Article",
    authors: ["N. al Makdessi", "M. Ecarnot", "P.Roumet", "G. Rabatel"],
    date: "Apr 2019",
    link: "https://www.researchgate.net/publication/328207649_A_spectral_correction_method_for_multi-scattering_effects_in_close_range_hyperspectral_imagery_of_vegetation_scenes_application_to_nitrogen_content_assessment_in_wheat",
  },
  {
    title:
      "Coupling Opto-Physical Modeling of Complex Vegetation Scenes and Chemometrics: Application to Proximal Hyperspectral Imaging-Based Phenotyping",
    type: "Thesis",
    authors: ["N. al Makdessi"],
    date: "Nov 2017",
    link: "https://www.researchgate.net/publication/328412317_Couplage_entre_modelisation_opto-physique_des_scenes_de_vegetation_complexes_et_chimiometrie_application_au_phenotypage_par_imagerie_hyperspectrale_de_proximite",
  },
  {
    title:
      "A Spectral Analysis Of Multiple Scattering Effects In Close Range Hyperspectral Imagery Of Vegetation Scenes: Application To Nitrogen Content Assessment",
    type: "Poster",
    authors: ["N. al Makdessi", "M. Ecarnot", "P.Roumet", "G. Rabatel"],
    date: "Jun 2017",
    link: "https://www.researchgate.net/publication/320170972_A_Spectral_Analysis_Of_Multiple_Scattering_Effects_In_Close_Range_Hyperspectral_Imagery_Of_Vegetation_Scenes_Application_To_Nitrogen_Content_Assessment",
  },
  {
    title:
      "How plant structure impacts the biochemical leaf traits assessment from in-field hyperspectral images: A simulation study based on light propagation modeling in 3D virtual wheat scenes",
    type: "Article",
    authors: ["N. al Makdessi", "M. Ecarnot", "P-A. Jean", "N. Gorretta"],
    date: "Apr 2017",
    link: "https://www.researchgate.net/publication/313868863_How_plant_structure_impacts_the_biochemical_leaf_traits_assessment_from_in-field_hyperspectral_images_A_simulation_study_based_on_light_propagation_modeling_in_3D_virtual_wheat_scenes",
  },
];

export const career = [
  {
    title: "Data Scientist Lecturer & Researcher",
    company: "ICAM",
    location: "Strasbourg, France",
    date: "2021 - Present",
    type: "Work",
    description: [
      "Responsible of Mathematics & Digital Preparatory Program",
      "Engaged in professional development and academic service",
      "Advised on student projects",
      "Developed course materials, such as assignments, lecture notes, projects",
      "Member of the data science graduation jury",
      "Conducted research in data science and developed statistical and machine learning models",
    ],
  },
  {
    title: "Data Science Diploma",
    company: "OpenClassrooms",
    location: "Centrale Supélec",
    type: "Education",
    date: "2022 - 2023",
  },
  {
    title: "Data Scientist Lecturer & Researcher",
    company: "Université Panthéon-Assas",
    location: "Paris, France",
    date: "2018 - 2021",
    type: "Work",
    description: [
      "Taught graduate data science courses about statistical analysis, machine learning, and data visualization",
      "Published research findings and presented at academic conferences and seminars",
      "Supervised MSc interns",
    ],
  },
  {
    title: "Research and Development Engineer",
    company: "SP3H",
    location: "Aix-En-Provence, France",
    date: "2017 - 2018",
    type: "Work",
    description: [
      "Analyzed optical sensor data to understand and optimize fuel characteristics in vehicle",
      "Developed machine learning models for fuel consumption and collaborated with engineers for sensor integration",
      "Conducted statistical analyses to enhance the sensor performances and identified opportunities to improve its accuracy and reliability",
    ],
  },
  {
    title: "PhD in Applied Mathematics",
    company: "Irstea",
    location: "Montpellier, France",
    date: "2014 - 2017",
    type: "Work",
    description: [
      "“Coupling Opto-Physical Modeling of Complex Vegetation Scenes and Chemometrics: Application to Proximal Hyperspectral Imaging-Based Phenotyping”",
      "Implemented machine learning algorithms for hyperspectral image analysis",
      "Extracted and manipulated data using Python and R, collaborating with cross-functional teams",
      "Mentored junior data scientists and presented findings to non-technical audiences",
      "Stayed up to date with industry trends and emerging technologies through research and conference attendance",
    ],
  },
  {
    title: "PhD in Applied Mathematics",
    company: "Irstea",
    location: "Montpellier, France",
    date: "2014 - 2017",
    type: "Education",
  },
  {
    title: "M2 Math Modeling and Application",
    company: "University Hadath",
    location: "Beirut, Lebanon",
    date: "2012 - 2014",
    type: "Education",
  },
];

export const networks = [
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/nathalie-al-makdessi/",
    icon: <FaLinkedinIn className="iconNetwork" />,
  },
  {
    name: "GitHub",
    link: "https://github.com/NathMak",
    icon: <BsGithub className="iconNetwork" />,
  },
  {
    name: "ResearchGate",
    link: "https://www.researchgate.net/profile/Nathalie-Al-Makdessi-2",
    icon: <SiResearchgate className="iconNetwork" />,
  },
];

export const testimonialList = [
  /*{
    quote: "Nathalie is the beautifulest women in the world.",
    author: "Charles Zimmerlin",
    icon: require("../assets/images/testimonial/charles.webp"),
  },*/
  {
    quote:
      "Nathalie Al Makdessi completed her PhD in hyperspectral imaging for crop phenotyping, showcasing strong adaptability and interpersonal skills. She is highly recommended for research teams.",
    author: "Gilles Rabatel",
    icon: require("../assets/images/testimonial/gilles.webp"),
  },
  {
    quote:
      "Nathalie is a very talented and hard-working data scientist. She is a great team player and a very good communicator. She is always willing to help and share her knowledge. I highly recommend her.",
    author: "ChatGPT",
    icon: require("../assets/images/testimonial/chatgpt.webp"),
  },
];

export const colors = ["var(--blue1)", "var(--pink1)", "var(--blue2)"];
