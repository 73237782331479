const SvgPath = (props) => {
  let classN = props.className || null;

  return (
    <svg
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 2000 367.87"
      className={classN ? classN : "svg"}
    >
      <g>
        <path
          className="st0"
          d="M-24.59,169.79c71.35-21.83,155.8-44.07,229.34-24.83c50.8,13.29,152.01,32.7,189.19,56.74
		c115.31,74.57,117.29,8.01,196.86-14.99s164.28-50.6,251.84-41.51c66.22,6.88,146.69,21.57,173.47,57.29
		c34.25,45.68,49.83,86.86,167.25,78.66c81.04-5.66,163.21,48.79,244.74,51.13c61.75,1.77,117.08-103.1,178.6-106.62
		c50.87-2.91,89.99-26.34,124.26-48.3C1823.24,118.24,1915.53,59.12,2007.81,0"
        />
        <path
          className="st1"
          d="M-21.23,186.38c83.62-13,178.72-71.11,259.78-52.06c50,11.75,143.32,31.39,181.68,55.83
		c105.51,67.23,113.26,14.17,187.88-0.32c76.36-14.82,157.12-32.44,239.2-21.81c24.33,3.15,50.23,6.96,74.57,11.61
		c40.56,7.61,75.99,20.25,93.63,39.53c37.91,41.44,55.17,79.95,163.1,74.18c18.65-1,37.6,0.95,56.99,4.22
		c61.01,10.16,119.33,33.83,176.37,30.06c58.6-3.87,109.97-101.63,170.55-104.18c30.37-1.28,55.22,12.27,92,5.02
		c36.74-7.25,56.08-20.68,79.6-33.67c91.07-50.27,177.21-113.97,269.25-171.71"
        />
        <path
          className="st2"
          d="M-17.87,202.97c95.89-4.17,201.59-98,290.22-79.29c49.14,10.38,134.62,30.1,174.18,54.93
		c95.62,60.01,109.43,20.52,178.9,14.36c73.35-6.5,149.95-14.18,226.56-2.12c23.62,3.72,48.35,7.67,71.62,11.35
		c39.24,6.2,72.85,17.24,91.32,33.63c41.87,37.18,60.52,73.11,158.95,69.71c17.64-0.61,35.82,1.11,54.83,3.55
		c60.31,7.51,114.95,22.99,167.16,13.89c55.6-9.69,102.87-99.98,162.49-101.74c34.75-1.02,61.56,33.03,114.3,27.65
		c53.64-5.48,74.16-21.58,104.65-36.64c88.35-43.65,169.85-109.69,261.66-166.06"
        />
        <path
          className="st3"
          d="M-14.51,219.56c108.16,4.66,224.4-124.71,320.66-106.52c48.26,9.12,125.9,28.84,166.67,54.02
		c85.66,52.9,106,23.18,169.91,29.03c70.27,6.43,142.78,4.17,213.93,17.57c22.92,4.32,46.49,8.33,68.66,11.09
		c37.95,4.71,69.87,14.14,89.01,27.74c46.13,32.77,65.89,66.36,154.8,65.23c16.63-0.21,34.03,1.31,52.66,2.88
		c59.59,5,110.63,11.87,157.95-2.29c52.68-15.77,95.77-98.22,154.44-99.29c39.13-0.72,67.9,53.84,136.59,50.27
		c70.54-3.66,92.09-22.73,129.7-39.62c84.99-38.17,162.49-105.42,254.06-160.41"
        />
        <path
          className="st4"
          d="M-11.15,236.15c120.43,13.49,247.18-151.23,351.1-133.75c47.35,7.97,117.16,27.61,159.16,53.11
		c75.63,45.91,102.17,34.16,160.93,43.71c67.8,11.02,135.6,22.64,201.29,37.26c22.21,4.95,44.62,8.99,65.71,10.83
		c36.66,3.21,67,10.92,86.7,21.84c50.64,28.09,71.27,59.72,150.66,60.76c15.62,0.2,32.24,1.47,50.49,2.21
		c58.89,2.36,106.29,0.45,148.73-18.46c49.74-22.16,88.68-96.38,146.39-96.85c43.51-0.36,74.24,74.82,158.89,72.9
		c87.44-1.66,109.88-24.2,154.74-42.6c81.35-33.35,155.13-101.15,246.46-154.75"
        />
        <path
          className="st5"
          d="M-7.79,252.75C124.91,275.07,262.13,75.2,373.75,91.77c46.43,6.89,108.38,26.44,151.65,52.21
		c65.53,39.02,98.52,41.58,151.95,58.39c64.99,20.44,128.39,41.22,188.65,56.96c21.5,5.61,42.76,9.62,62.75,10.57
		c35.37,1.68,64.19,7.55,84.39,15.95c55.34,23.01,76.65,53.21,146.51,56.28c14.61,0.64,30.46,1.63,48.32,1.53
		c58.19-0.31,101.8-11.27,139.52-34.64c46.63-28.89,81.59-95.15,138.33-94.41c47.89,0.62,80.57,95.6,181.19,95.52
		c104.33,0.14,127.53-26.07,179.79-45.57c77.58-28.96,147.77-96.87,238.87-149.1"
        />
        <path
          className="st6"
          d="M-4.44,269.34C140.54,300.49,288.22,65.7,407.55,81.13c45.51,5.88,99.55,25.35,144.15,51.3
		c55.38,32.22,94.62,49.35,142.96,73.06c61.87,30.34,121.14,59.92,176.02,76.65c20.76,6.33,40.89,10.25,59.79,10.32
		c34.08,0.12,61.39,4.04,82.08,10.05c60.08,17.48,82.01,46.85,142.36,51.8c13.6,1.12,28.67,1.75,46.16,0.86
		c57.49-3.07,97.06-23.18,130.31-50.81c43.2-35.91,74.49-92.53,130.28-91.96c52.27,0.54,86.9,116.39,203.48,118.15
		c121.22,1.94,145.05-28.4,204.84-48.55c73.8-24.87,140.41-92.6,231.27-143.45"
        />
        <path
          className="st7"
          d="M-1.08,285.93C156.17,325.92,314.29,56.44,441.35,70.49c170.44,18.85,362.2,250.27,490.84,244.53
		c157.48-7.03,153.03,62.64,261.97,51.68c133.65-13.45,147.98-158.31,243.33-156.5c56.65,1.07,93.23,137.18,225.78,140.77
		c138.11,3.75,162.44-31.31,229.89-51.53c70.08-21,133.05-88.33,223.67-137.8"
        ></path>
      </g>
    </svg>
  );
};

export default SvgPath;
