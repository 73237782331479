import { useState } from "react";
import { Link } from "react-scroll";
import { ParallaxProvider } from "react-scroll-parallax";
import { BsChevronUp } from "react-icons/bs";
import { useScroll, useMotionValueEvent } from "framer-motion";

import Home from "./sections/Home";
import About from "./sections/About";
import Skills from "./sections/Skills";
import Publications from "./sections/Publications";
import Career from "./sections/Career";
import Contact from "./sections/Contact";

import SvgPath from "./components/SvgPath";
import Header from "./components/Header";

import "./App.css";
import Testimonial from "./sections/Testimonial";

function App() {
  const [bg, setBg] = useState("rgba(248,249,250,1)");
  const [opacity, setOpacity] = useState(1);
  const [display, setDisplay] = useState("flex");
  setTimeout(() => {
    setBg("rgba(248,249,250,0)");
  }, 1500);
  setTimeout(() => {
    setOpacity(0);
  }, 1300);
  setTimeout(() => {
    setDisplay("none");
  }, 1900);

  const { scrollY } = useScroll();
  const [opa, setOpa] = useState(0);
  useMotionValueEvent(scrollY, "change", (latest) => {
    if (latest > 100) {
      setOpa(1);
    }
    if (latest < 100) {
      setOpa(0);
    }
  });
  return (
    <ParallaxProvider>
      <div
        className="overlay"
        style={{ backgroundColor: bg, display: display, opacity: opacity }}
      >
        <SvgPath />
      </div>
      <Link
        href="#"
        style={{ opacity: opa }}
        to={"home"}
        className="upButton"
        spyThrottle={2500}
        spy={true}
        offset={-60}
      >
        <BsChevronUp className="chevronUp" />
      </Link>
      <div className="App">
        <Header />
        <Home />
        <About />
        <Skills />
        <Publications />
        <Career />
        <Testimonial />
        <Contact />
      </div>
    </ParallaxProvider>
  );
}

export default App;
