/* eslint-disable react/style-prop-object */

import "../styles/SvgAtom.css";

const SvgAtom = () => {
  let angles = [];
  let keyS = [];
  let orbitNumber = 4;
  for (let i = 0; i < 180; i += parseInt(180 / orbitNumber, 10)) {
    angles.push(i);
    let key = [];
    for (let j = 0; j < 4; j++) {
      key.push(Math.random() * 0.2 + 0.2);
    }
    keyS.push(key.join(" "));
  }
  return (
    <svg
      className="svg-atom"
      width="100%"
      height="100%"
      viewBox="0 0 400 400"
      xmlns="http://www.w3.org/2000/svg"
    >
      {angles.map((angle, index) => (
        <g key={angle} transform={"rotate(" + angle.toString() + ",200,200)"}>
          <path
            id="p1"
            d="M150,200A50,150,0,0,1,250,200A50,150,0,0,1,150,200z"
            strokeWidth={1}
            stroke="var(--gray3)"
            fill="none"
          />
          <circle r="5" fill="url(#electron-gradient)" className="electron">
            <animateMotion
              calcMode="spline"
              keyTimes="0;1"
              keySplines={keyS[index]}
              dur={Math.random() * 8 + 5 + "s"}
              repeatCount="indefinite"
            >
              <mpath href="#p1" />
            </animateMotion>
          </circle>
        </g>
      ))}
      <g id="nucleus" strokeWidth="0.5">
        <circle
          cx="200"
          cy="200"
          r="10"
          fill="url(#core-gradient)"
          className="nucleus"
        />
      </g>
      <defs>
        <radialGradient
          id="core-gradient"
          cx="50%"
          cy="50%"
          r="50%"
          fx="50%"
          fy="50%"
        >
          <stop offset="0%" stopColor="#8d8dbc" stopOpacity="1" />
          <stop offset="100%" stopColor="#8472dd" stopOpacity="1" />
        </radialGradient>
        <radialGradient
          id="electron-gradient"
          cx="50%"
          cy="50%"
          r="50%"
          fx="50%"
          fy="50%"
        >
          <stop offset="0%" stopColor="var(--pink1)" stopOpacity="1" />
          <stop offset="100%" stopColor="var(--pink2)" stopOpacity="1" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default SvgAtom;
