import React from "react";
import { motion } from "framer-motion";

import ContactForm from "../components/ContactForm";

import { networks } from "../assets/Data";

import "../styles/Contact.css";

const Contact = React.forwardRef((props, ref) => {
  return (
    <section className="contact" ref={ref}>
      <motion.div
        transition={{
          ease: "easeInOut",
          delay: 0.1,
          duration: 0.3,
        }}
        initial={{ opacity: 0, transform: "translateX(-30px)" }}
        whileInView={{ opacity: 1, transform: "translateX(0px)" }}
        viewport={{ once: true }}
      >
        <h1>Contact</h1>
      </motion.div>
      <div className="contactForm">
        <ContactForm />
      </div>
      <div className="networks">
        {networks.map((network, index) => {
          return (
            <a rel="noreferrer" key={index} href={network.link} target="_blank">
              {network.icon}
            </a>
          );
        })}
      </div>
      <motion.div
        className="copyright"
        transition={{
          ease: "easeInOut",
          delay: 0.1,
          duration: 0.3,
        }}
        initial={{ opacity: 0, transform: "translateY(30px)" }}
        whileInView={{ opacity: 1, transform: "translateX(0px)" }}
        viewport={{ once: true }}
      >
        © {new Date().getFullYear()} Nathalie Al Makdessi
      </motion.div>
    </section>
  );
});

export default Contact;
