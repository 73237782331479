import "../styles/Skills.css";

const SkillBox = (props) => {
  let title = props.title || "Skill";
  let skills = props.skills || [];
  return (
    <div className="skillBox">
      <h3>{title}</h3>
      <div className="skillCarts">
        {skills.map((skill, index) => {
          return (
            <div className="skillCart" key={index}>
              <div className="skillIconCont">
                <img
                  className="skillIcon"
                  src={skill.icon}
                  alt={skill.name}
                  width={50}
                  height={50}
                />
              </div>
              <div className="skillName">{skill.name}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SkillBox;
